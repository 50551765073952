import FormStructure from "../../components/Forms/FormStructure/FormStructure";
import FormsNav from "../../components/Forms/FormsNav/FormsNav";

const FreeTrial = () => {
  return (
    <div className="bg-[#F5F8FE] min-h-screen max-h-full">
      <FormsNav />
      <FormStructure
        header="Sign up for a free trial"
        desc="30 days of productivity boost at no cost"
        serviceType="free_trial"
        image="freeTrial.png"
        buttonName="Sign me up"
      />
    </div>
  );
};

export default FreeTrial;
