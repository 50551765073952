import { ErrorMessage, Field, FormikValues } from "formik";
import { BiTimeFive } from "react-icons/bi";
import MultiStepForm, { FormStep } from "./MultiStepForm";
import { times } from "./shared";
import "react-calendar/dist/Calendar.css";
import { useState } from "react";
import ScheduleSubmitSuccess from "./ScheduleSubmitSuccess";
import * as Yup from "yup";
import { countries } from "../../Countries/Countries";
import { DayOfWeek, DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
const axios = require("axios").default;

const ScheduleStructure = () => {
  let navigate = useNavigate();
  const [openSuccess, setOpenSuccess] = useState(false);

  const [selected, setSelected] = useState<Date>();

  const [time, setTime] = useState("");

  const [clicked, setClicked] = useState<number | null>(null);

  // const WEEKDAYS_SHORT = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

  const toggle = (id: number, timeObject: any, setFieldValue: any) => {
    setTime(timeObject.timeFrame);
    if (id === timeObject.id) {
      setClicked(id);
    }
    setFieldValue("timeSelect", timeObject.timeFrame);
  };

  interface myFormValues {
    firstName: string;
    lastName: string;
    workEmail: string;
    phoneNumber: string;
    companyName: string;
    jobTitle: string;
    companySize: string;
    country: string;
    hearAbout: string;
    timeSelect: string;
    dateSelect?: string;
  }

  const initialValues: myFormValues = {
    firstName: "",
    lastName: "",
    workEmail: "",
    phoneNumber: "",
    companyName: "",
    jobTitle: "",
    companySize: "",
    country: "",
    hearAbout: "",
    timeSelect: time,
    dateSelect: (selected && format(selected, "PP")) ?? "",
  };

  const dateAndTimeValidationSchema = Yup.object({
    dateSelect: Yup.string().required(),
    timeSelect: Yup.string().required(),
  });

  const formValidationSchema = Yup.object({
    firstName: Yup.string().required("*Required"),
    lastName: Yup.string().required("*Required"),
    workEmail: Yup.string()
      .email("*Invalid email address")
      .required("*Required"),
    phoneNumber: Yup.string()
      .phone(undefined, true, "*Invalid Phone Number")
      .required(),
    companyName: Yup.string().required("*Required"),
    jobTitle: Yup.string().required("*Required"),
    companySize: Yup.string().required("*Required"),
    country: Yup.string().required("*Required"),
    hearAbout: Yup.string().required("*Required"),
  });

  const handleSubmit = (values: FormikValues, onSubmitProps: any) => {
    const {
      timeSelect,
      firstName,
      lastName,
      workEmail,
      phoneNumber,
      companyName,
      jobTitle,
      companySize,
      country,
      hearAbout,
    } = values;

    //https://script.google.com/macros/s/AKfycbyvWIsl9pUw41bqFcl5YVcfads43c0TUA6PoNSVadBmhYxC8fbME7ZhgFriQP-fqbuV/exec?service_type=schedule_demo&time_select=${timeSelect}&date_select=${dateSelect}&first_name=${firstName}&last_name=${lastName}&email=${workEmail}&phone_no=${phoneNumber}&org_name=${companyName}&job_title=${jobTitle}&company_size=${companySize}&country=${country}&how_did_you_hear=${hearAbout}`

    const dateSelect = selected && format(selected, "PP");
    const config = {
      url: `https://api.omnibranches.com/v1/create-form?service_type=schedule_demo&time_select=${timeSelect}&date_select=${dateSelect}&first_name=${firstName}&last_name=${lastName}&email=${workEmail}&phone_no=${phoneNumber}&org_name=${companyName}&job_title=${jobTitle}&company_size=${companySize}&country=${country}&how_did_you_hear=${hearAbout}`,
      headers: {
        "Content-Type": "text/plain",
      },
    };

    const mailAPI = {
      from: "SwiftaHR <hrms@swiftaerp.com>",
      to: ["sales@swifta.com"],
      domain: "hrms",
      subject: "Free demo booked",
      text: "Texting",
      content:
        '<div className="h-14 rounded-md p-4 bg-slate-400 text-center"><p>You have a new lead for SwiftaHR Free Demo.<br /><br /> Click the <a href="https://docs.google.com/spreadsheets/d/1gY-7IEoLP4lCqjgGk2QS8Vohtsh-KITVRbPO7WthujU/edit#gid=834424358">link</a> to view. You will need to refresh the sheet to see the updates via these steps: <br /><br />SwiftaMenu ➡️ Refresh Sheet ➡️ Ok</p> </div>',
    };

    const clientMailAPI = {
      from: "SwiftaHR <hrms@swiftaerp.com>",
      to: [`${workEmail}`],
      domain: "hrms",
      subject: "Your free demo is confirmed",
      text: "Texting",
      content: `<div className="h-14 rounded-md p-4 bg-slate-400 text-center"><p>Dear ${firstName}, <br /><br /> We are thrilled to receive your request and can't wait to show you how our HR solution will increase your employee productivity. <br />Our sales team will contact you shortly to confirm your booking.<br /> <br />For more information or assistance, please contact us at sales@swifta.com.</p> </div>`,
    };

    axios(config)
      .then((res: any) => {
        setOpenSuccess(true);
        onSubmitProps.resetForm();

        onSubmitProps.setSubmitting(false);

        axios
          .post("https://api.omnibranches.com/v1/send-mail", mailAPI)
          .then((res: any) => {})
          .catch((err: any) => {
            // console.log(err);
          });
        axios
          .post("https://api.omnibranches.com/v1/send-mail", clientMailAPI)
          .then((res: any) => {})
          .catch((err: any) => {
            // console.log(err);
          });
      })
      .catch((err: any) => {
        // console.log(err);
      });
  };

  const dayOfWeekMatcher: DayOfWeek = {
    dayOfWeek: [0, 6],
  };

  return (
    <div>
      <div className="container">
        <div className="mobile:py-5 md:p-9 mt-[67px] md:mt-[90px]">
          <div className="bg-white shadow-form rounded-xl p-7 flex mobile:flex-col h-full md:min-h-[652px] mobile:space-y-4">
            <div className="md:max-w-[270px] md:px-7 md:border-r md:border-opacity-30 md:border-r-secondary flex flex-col space-y-5 justify-between pb-4 bg-white">
              <div className="">
                <h1 className="font-secondary font-bold text-[24px] md:text-[26px] text-tertiary">
                  Schedule a free demo with us
                </h1>
                <p className="font-primary font-medium text-tertiary mt-1">
                  Pick your time and we'll show up
                </p>
                <div className="bg-[#F5F8FE] space-x-1 flex items-center py-2 px-5 rounded-md mt-4 w-fit">
                  <BiTimeFive size={21} color="#649AE9" />
                  <p className="font-primary font-bold text-primary text-lg">
                    45 - 60 min
                  </p>
                </div>
              </div>
              <div className="bg-[#EAEAEA] bg-opacity-20 rounded p-4 md:space-y-5 mobile:space-x-2 flex justify-between md:flex-col  ">
                <div className="space-y-2 ">
                  <p className="text-base font-primary text-secondary">Date</p>
                  <p className="text-lg font-primary font-bold text-secondary">
                    {selected ? format(selected, "PP") : ""}
                  </p>
                </div>
                <div className="space-y-2">
                  <p className="text-base font-primary text-secondary">Time</p>
                  <p className="text-lg font-primary font-bold text-secondary">{`${
                    !initialValues.timeSelect ? "" : initialValues.timeSelect
                  }`}</p>
                </div>
              </div>
            </div>
            <div className="md:px-5 w-full">
              {/* <ScheduleForm /> */}
              {/* Form Renderrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr */}

              <MultiStepForm
                enableReinitialize={true}
                initialValues={initialValues}
                onSubmit={handleSubmit}
              >
                <FormStep
                  stepName="Date and Time"
                  validationSchema={dateAndTimeValidationSchema}
                >
                  <div className="flex flex-col space-y-7">
                    <p className="text-lg font-secondary font-medium text-secondary">
                      Please select date and time
                    </p>
                    <div className="flex md:flex-row md:flex-wrap md:justify-between flex-col space-x-9">
                      <div
                        className={`flex grow z-0 ${
                          selected && "mobile:hidden"
                        }`}
                      >
                        <Field>
                          {({ form }: any) => {
                            const { setFieldValue } = form;
                            return (
                              <DayPicker
                                required
                                mode="single"
                                selected={selected}
                                onSelect={setSelected}
                                onDayClick={(selected) =>
                                  setFieldValue("dateSelect", selected)
                                }
                                disabled={[
                                  { before: new Date() },
                                  { after: new Date(Date.now() + 12096e5) },
                                  { ...dayOfWeekMatcher },
                                ]}
                                weekStartsOn={1}
                                fromMonth={
                                  new Date(
                                    new Date().getFullYear(),
                                    new Date().getMonth()
                                  )
                                }
                                toMonth={
                                  new Date(
                                    new Date().getFullYear(),
                                    new Date().getMonth() + 1
                                  )
                                }
                              />
                            );
                          }}
                        </Field>
                      </div>
                      <div
                        className={`space-y-5 overflow-y-scroll scrollbar px-2 scrollbar-thumb-primary scrollbar-track-inherit scrollbar-thumb-rounded max-h-[400px] flex self-center flex-col w-fit ${
                          !selected && "mobile:hidden"
                        }`}
                      >
                        {times.map((time, idx) => (
                          <Field key={idx}>
                            {({ form }: any) => {
                              const { setFieldValue } = form;
                              return (
                                <div
                                  onClick={() =>
                                    toggle(time.id, time, setFieldValue)
                                  }
                                  // onClick={timeSelect}
                                  className={`${
                                    clicked === time.id
                                      ? "text-primary border border-primary"
                                      : "text-secondary border border-secondary"
                                  } w-40 py-[10px] h-10  flex items-center justify-center cursor-pointer rounded text-lg font-primary hover:text-primary hover:border-primary
                            
                          `}
                                >
                                  {time.time}
                                </div>
                              );
                            }}
                          </Field>
                        ))}
                      </div>
                    </div>
                  </div>
                </FormStep>

                <FormStep
                  stepName="Form"
                  validationSchema={formValidationSchema}
                >
                  <div className="grid md:grid-cols-formFields mobile:grid-cols-mobileFormFields md:gap-x-10 gap-y-5 ">
                    <div className="flex flex-col space-y-2">
                      <div className="flex justify-between items-center">
                        <label
                          htmlFor="firstName"
                          className="text-tertiary font-primary font-medium"
                        >
                          First name
                        </label>
                        <ErrorMessage
                          name="firstName"
                          component="div"
                          className="text-red-500 text-xs font-primary"
                        />
                      </div>
                      <Field
                        name="firstName"
                        id="firstName"
                        type="text"
                        className=" h-10 px-3 border border-solid border-secondary border-opacity-30 rounded-md focus:border focus:outline-none focus:border-primary"
                      />
                    </div>
                    <div className="flex flex-col space-y-2">
                      <div className="flex justify-between items-center">
                        <label
                          htmlFor="lastName"
                          className="text-tertiary font-primary font-medium"
                        >
                          Last name
                        </label>
                        <ErrorMessage
                          name="lastName"
                          component="div"
                          className="text-red-500 text-xs font-primary"
                        />
                      </div>
                      <Field
                        name="lastName"
                        id="lastName"
                        type="text"
                        className=" h-10 px-3 border border-solid border-secondary border-opacity-30 rounded-md focus:border focus:outline-none focus:border-primary"
                      />
                    </div>
                    <div className="flex flex-col space-y-2">
                      <div className="flex justify-between items-center">
                        <label
                          htmlFor="workEmail"
                          className="text-tertiary font-primary font-medium"
                        >
                          Work email
                        </label>
                        <ErrorMessage
                          name="workEmail"
                          component="div"
                          className="text-red-500 text-xs font-primary"
                        />
                      </div>
                      <Field
                        name="workEmail"
                        id="workEmail"
                        type="text"
                        className=" h-10 px-3 border border-solid border-secondary border-opacity-30 rounded-md focus:border focus:outline-none focus:border-primary"
                      />
                    </div>
                    <div className="flex flex-col space-y-2">
                      <div className="flex justify-between items-center">
                        <label
                          htmlFor="phoneNumber"
                          className="text-tertiary font-primary font-medium"
                        >
                          Phone number
                        </label>
                        <ErrorMessage
                          name="phoneNumber"
                          component="div"
                          className="text-red-500 text-xs font-primary"
                        />
                      </div>
                      <Field
                        name="phoneNumber"
                        id="phoneNumber"
                        type="text"
                        className=" h-10 px-3 border border-solid border-secondary border-opacity-30 rounded-md focus:border focus:outline-none focus:border-primary"
                      />
                    </div>
                    <div className="flex flex-col space-y-2">
                      <div className="flex justify-between items-center">
                        <label
                          htmlFor="companyName"
                          className="text-tertiary font-primary font-medium"
                        >
                          Company name
                        </label>
                        <ErrorMessage
                          name="companyName"
                          component="div"
                          className="text-red-500 text-xs font-primary"
                        />
                      </div>
                      <Field
                        name="companyName"
                        id="companyName"
                        type="text"
                        className=" h-10 px-3 border border-solid border-secondary border-opacity-30 rounded-md focus:border focus:outline-none focus:border-primary"
                      />
                    </div>
                    <div className="flex flex-col space-y-2">
                      <div className="flex justify-between items-center">
                        <label
                          htmlFor="jobTitle"
                          className="text-tertiary font-primary font-medium"
                        >
                          Job title
                        </label>
                        <ErrorMessage
                          name="jobTitle"
                          component="div"
                          className="text-red-500 text-xs font-primary"
                        />
                      </div>
                      <Field
                        name="jobTitle"
                        id="jobTitle"
                        type="text"
                        className=" h-10 px-3 border border-solid border-secondary border-opacity-30 rounded-md focus:border focus:outline-none focus:border-primary"
                      />
                    </div>
                    <div className="flex flex-col space-y-2">
                      <div className="flex justify-between items-center">
                        <label
                          htmlFor="companySize"
                          className="text-tertiary font-primary font-medium"
                        >
                          Company size
                        </label>
                        <ErrorMessage
                          name="companySize"
                          component="div"
                          className="text-red-500 text-xs font-primary"
                        />
                      </div>
                      <Field
                        name="companySize"
                        id="companySize"
                        as="select"
                        className=" h-10 px-3 border border-solid border-secondary border-opacity-30 rounded-md focus:border focus:outline-none focus:border-primary"
                      >
                        <option value="" label="What is your company size?" />
                        <option value="20 to 50"> 20 to 50</option>
                        <option value="51 to 100">51 to 100</option>
                        <option value="101 to 500">101 to 500</option>
                        <option value="501 to 1000">501 to 1000</option>
                        <option value="More than 1000">More than 1000</option>
                      </Field>
                    </div>
                    <div className="flex flex-col space-y-2">
                      <div className="flex justify-between items-center">
                        <label
                          htmlFor="country"
                          className="text-tertiary font-primary font-medium"
                        >
                          Country
                        </label>
                        <ErrorMessage
                          name="country"
                          component="div"
                          className="text-red-500 text-xs font-primary"
                        />
                      </div>
                      <Field
                        name="country"
                        id="country"
                        as="select"
                        className=" h-10 px-3 border border-solid border-secondary border-opacity-30 rounded-md focus:border focus:outline-none focus:border-primary  "
                      >
                        {countries.map((country) => (
                          <option
                            key={country.name}
                            value={country.name}
                            className="max-w-[340px]"
                          >
                            {country.name}
                          </option>
                        ))}
                      </Field>
                    </div>
                    <div className="flex flex-col space-y-2">
                      <div className="flex justify-between items-center">
                        <label
                          htmlFor="hearAbout"
                          className="text-tertiary font-primary font-medium"
                        >
                          How did you hear about us?
                        </label>
                        <ErrorMessage
                          name="hearAbout"
                          component="div"
                          className="text-red-500 text-xs font-primary"
                        />
                      </div>
                      <Field
                        name="hearAbout"
                        id="hearAbout"
                        as="select"
                        className=" h-10 px-3 border border-solid border-secondary border-opacity-30 rounded-md focus:border focus:outline-none focus:border-primary"
                      >
                        <option value="" label="Select" />
                        <option value="Facebook">Facebook</option>
                        <option value="Friends/Colleagues">
                          Friends/Colleagues
                        </option>
                        <option value="Instagram">Instagram</option>
                        <option value="Linkedin">Linkedin</option>
                        <option value="Twitter">Twitter</option>
                        <option value="Others">Others</option>
                      </Field>
                    </div>
                  </div>
                </FormStep>
              </MultiStepForm>
            </div>
          </div>
        </div>
      </div>
      <ScheduleSubmitSuccess
        open={openSuccess}
        handleclose={() => {
          setOpenSuccess(false);
          navigate("/");
        }}
        date={selected && format(selected, "PP")}
        time={initialValues.timeSelect}
      />
    </div>
  );
};

export default ScheduleStructure;
