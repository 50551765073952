import "./App.css";
import FreeTrial from "./pages/FreeTrial/FreeTrial";
import Home from "./pages/Home/Home";
import { Route, Routes } from "react-router-dom";
import CustomRequest from "./pages/CustomRequest/CustomRequest";
import ScheduleDemo from "./pages/ScheduleDemo/ScheduleDemo";
import TermsOfUse from "./pages/Legal/TermsOfUse";
import PrivacyPolicy from "./pages/Legal/PrivacyPolicy";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/free-trial" element={<FreeTrial />} />
        <Route path="/custom-request" element={<CustomRequest />} />
        <Route path="/schedule-demo" element={<ScheduleDemo />} />
        <Route path="/terms-of-use" element={<TermsOfUse />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      </Routes>
    </div>
  );
}

export default App;
