export const modules = [
  {
    id: 1,
    title: { topic: "Employee Management", icon: "employeeManagement.svg" },
    content: {
      title: "Manage employee profiles seamlessly",
      description:
        "Add new employees, configure their profiles with departments, designations, grades and even branches",
      image: "employeeManagement.png",
      alt: "Swifta Employee Management System",
    },
  },
  {
    id: 2,
    title: { topic: "Leave Management", icon: "leaveManagement.svg" },
    content: {
      title: "No more leave application on paper",
      description:
        "Apply, relieve, approve, and track with an end-to-end automated leave management process",
      image: "leaveManagement.png",
      alt:"Swifta Leave Management System"
    },
  },
  {
    id: 3,
    title: {
      topic: "Performance Management",
      icon: "performanceManagement.svg",
    },
    content: {
      title: "Keep your workforce performance up",
      description:
        "Track and evaluate each employee's contribution against set goals with unbiased comprehensive report generation",
      image: "performanceManagement.png",
      alt: "Swifta Performance Management System"
    },
  },
  {
    id: 4,
    title: { topic: "Employee Lifecycle", icon: "employeeLifecycle.svg" },
    content: {
      title: "Hire-to-retire process no longer tedious",
      description:
        "Implement a people-centric strategy with a lifecycle management tool that drives employee engagement and retention",
      image: "employeeLifecycle.png",
      alt:"Swifta Employee Lifecycle System",
    },
  },
  {
    id: 5,
    title: { topic: "Payroll", icon: "payroll.svg" },
    content: {
      title: "Automate your payroll process",
      description:
        "Streamline payroll entries to a few clicks, track employees' activities on register, and create salary slips on demand",
      image: "payroll.png",
      alt: "Payroll System",
    },
  },
  {
    id: 6,
    title: { topic: "Reports", icon: "reports.svg" },
    content: {
      title: "Detailed reporting for better analysis",
      description:
        "Customize your reports with ease from templates, download, share and control access to generated reports",
      image: "reports_new.png",
      alt: "Swifta Report System",
    },
  },
];
