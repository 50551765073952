import React from "react";
import FormsNav from "../../components/Forms/FormsNav/FormsNav";
import FormStructure from "../../components/Forms/FormStructure/FormStructure";

const CustomRequest = () => {
  return (
    <div className="bg-[#F5F8FE] h-screen">
      <FormsNav />
      <FormStructure
        header="Customize your HR solution"
        desc="We'll tailor it to meet your HR need"
        serviceType="custom_request"
        image="customRequest.svg"
        buttonName="Let's customize"
      />
    </div>
  );
};

export default CustomRequest;
