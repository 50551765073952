export const times = [
  { time: "9.00 am", timeFrame: "9.00 am - 10.00 am", id: 1 },
  { time: "10.00 am", timeFrame: "10.00 am - 11.00 am", id: 2 },
  { time: "11.00 am", timeFrame: "11.00 am - 12.00 am", id: 3 },
  { time: "12.00 pm", timeFrame: "12.00 pm - 1.00 pm", id: 4 },
  { time: "1.00 pm", timeFrame: "1.00 pm - 2.00 pm", id: 5 },
  { time: "2.00 pm", timeFrame: "2.00 pm - 3.00 pm", id: 6 },
  { time: "3.00 pm", timeFrame: "3.00 pm - 4.00 pm", id: 7 },
  { time: "4.00 pm", timeFrame: "4.00 pm - 5.00 pm", id: 8 },
  { time: "5.00 pm", timeFrame: "5.00 pm - 6.00 pm", id: 9 },
];
