import FormDetail from "./FormDetail";

interface formProps {
  header: string;
  desc: string;
  serviceType?: string;
  image: string;
  buttonName: string;
}

const FormStructure = ({
  header,
  desc,
  serviceType,
  image,
  buttonName,
}: formProps) => {
  return (
    <div className="container">
      <div className="mobile:py-5 md:p-9 mt-[67px] md:mt-[90px]">
        <div className="bg-white shadow-form rounded-xl p-7 flex flex-col ">
          {/* left */}
          <div className="flex mobile:flex-col ">
            <div className="md:max-w-[270px] md:px-7 md:border-r md:border-opacity-30 md:border-r-secondary flex flex-col justify-between mobile:bg-white py-4">
              <div className="">
                <h1 className="font-secondary font-bold text-[24px] md:text-[26px] text-tertiary">
                  {header}
                </h1>
                <p className="font-primary font-medium text-tertiary">{desc}</p>
              </div>
              <div className="mobile:hidden">
                <img src={image} alt="Free Trial" />
              </div>
            </div>
            {/* left end */}
            {/* Form */}

            <div className="md:px-16">
              <h1 className="font-secondary text-lg text-secondary  ">
                Enter your information
              </h1>
              <FormDetail buttonName={buttonName} serviceType={serviceType} />
            </div>
          </div>
          <div>
            {/* <button className="h-11 bg-primary text-white rounded-md font-primary font-bold px-9 ">
              {buttonName}
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormStructure;
