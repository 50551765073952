import FormsNav from "../../components/Forms/FormsNav/FormsNav";
import ScheduleStructure from "../../components/Forms/ScheduleStructure/ScheduleStructure";

const ScheduleDemo = () => {
  return (
    <div className="bg-[#F5F8FE] min-h-screen max-h-full">
      <FormsNav />
      <ScheduleStructure />
    </div>
  );
};

export default ScheduleDemo;
